import { useNavigate } from "react-router-dom";
import GlobalContext from "../GlobalContext";
import { useContext } from "react";

function UserCards({ d }) {
  const { setCardInfomrationData } = useContext(GlobalContext);
  const navigate = useNavigate();

  const gameInformation = (d) => {
    setCardInfomrationData("");
    navigate(`../games-info/${d}`);
  };

  return (
    <>
      <button
        className="game-card"
        key={d.id}
        onClick={() => gameInformation(d.game_id)}
      >
        <div className="game-card-image">
          <img
            className="test"
            src={d.background_image || "../test.jpg"}
            alt="Video game cover"
            width="300px"
            height="170px"
          />
          <div className="game-card-consoles">
            <ul>
              {d.parent_platforms.some((e) => e.platform.name === "PC") ? (
                <li>
                  <img
                    src="../pc.svg"
                    alt="Platform logo"
                    width="30px"
                    height="30px"
                  />
                </li>
              ) : (
                ""
              )}
              {d.parent_platforms.some(
                (e) => e.platform.name === "PlayStation"
              ) ? (
                <li>
                  <img
                    src="../playstation.svg"
                    alt="Platform logo"
                    width="30px"
                    height="30px"
                  />
                </li>
              ) : (
                ""
              )}
              {d.parent_platforms.some((e) => e.platform.name === "Xbox") ? (
                <li>
                  <img
                    src="../xbox.svg"
                    alt="Platform logo"
                    width="30px"
                    height="30px"
                  />
                </li>
              ) : (
                ""
              )}
              {d.parent_platforms.some(
                (e) => e.platform.name === "Nintendo"
              ) ? (
                <li>
                  <img
                    src="../nintendo.svg"
                    alt="Platform logo"
                    width="30px"
                    height="30px"
                  />
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
        <div className="game-card-title">
          <h3 className="game-card-name">{d.name}</h3>
          <p className="game-card-score">{d.metacritic}</p>
          <p className="game-card-release">{d.released.substring(0, 4)}</p>
          {d.rating !== 0 && <p className="game-card-rating">{d.rating}</p>}
        </div>
        <p
          className="game-card-gamestatus"
          data={
            d.gameStatus === "Playing"
              ? "Playing"
              : d.gameStatus === "Completed"
              ? "Completed"
              : null
          }
        ></p>
      </button>
    </>
  );
}

export default UserCards;
